<template>
    <div>
        <h2>哈哈哈哈</h2>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>