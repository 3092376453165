import axios from "axios";
// 服务器路径（请求的基础路径）
// 方案一：直接给原本的 axios 设置基础路径
// axios.defaults.baseURL = 'http://nocat.life:3011';
// 方案二（推荐）：创建一个新的 axios，给新的 axios 设置基础路径
const instance = axios.create({
    baseURL: 'https://wj.medicup.cn'
})

instance.interceptors.response.use((res)=>{
    return res.data
})

export default instance;
