<template>
    <div>
        <!-- <h1>llllle</h1> -->
        <hello></hello>
    </div>
</template>

<script>
import hello from "./components/day1/hello.vue";
export default {
    components: { hello },
};
</script>

<style>
</style>
