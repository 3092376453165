<template>
    <div>
        <div
            class="over_page"
            style="
                background-color: #d0e5fb;
                position: relative;
                padding-top: 1px;
            "
        >
            <div
                style="max-width: 1200px; margin: 0 auto; padding: 0 30px"
                id="page_top"
            >
                <header class=" " role="banner">
                    <div class="flex justify-between">
                        <div>
                            <img :src="logo" alt="" srcset="" />
                        </div>
                        <ul >
                            <li :class="header_number==1?'act':''" @click="click_header('1')"><a href=""> 美佑医疗</a></li>
                            <li :class="header_number==2?'act':''" @click="click_header('2')"><a href="#youshi"> 优势体系</a></li>
                            <li :class="header_number==3?'act':''" @click="click_header('3')">
                                <a href="#jieshao"> 产品介绍</a>
                            </li>
                            <li :class="header_number==4?'act':''" @click="click_header('4')"><a href="#liucheng"> 工作流程</a></li>
                            <li :class="header_number==5?'act':''" @click="click_header('5')"><a href="#wenti"> 常见问题</a></li>
                            <!-- <li><a href=""> 知识更新</a></li> -->
                        </ul>
                    </div>
                </header>
                <div>
                    <div class="flex align-center justify-between" id="xg1">
                        <div style="margin-top: 70px">
                            <div class="title_size"
                                style="color: rgb(40, 50, 78); "
                            >
                                <strong>体检质控 智慧运营</strong>
                            </div>
                            <div class="title_size"
                                style="
                                    color: rgb(235, 107, 86);
                                    margin: 10px 0 20px;
                                "
                            >
                                <strong>选择美佑 相拥美好</strong>
                            </div>
                            <div
                                style="
                                    color: rgb(235, 107, 86);
                                    font-size: 24px;
                                "
                            >
                                <strong
                                    >基于国家重点研发计划核心示范任务研发</strong
                                >
                            </div>
                            <div
                                style="
                                    color: rgb(71, 85, 119);
                                    margin: 12px 0 10px;
                                "
                            >
                                <span>多学科门户 医疗温度管理</span>
                            </div>
                            <div style="color: rgb(71, 85, 119)">
                                <span
                                    >国家标准构体检预约体系/随访体系/阳性管理/学科质控</span
                                >
                            </div>
                            <div
                                class="flex justify-between"
                                style="width: 432px; margin: 50px 0"
                                id="xg2"
                            >
                                <div>
                                    <a href="#hezuo" class="btn_l">立即联系</a>
                                </div>
                                <div>
                                    <a href="" class="btn_l btn_r">了解更多</a>
                                </div>
                            </div>
                        </div>
                        <div class="aa" style="margin-top: 80px">
                            <img
                                :src="t1"
                                alt="Shake Image"
                                style="
                                    margin-right: 20px;
                                    width: 100%;
                                    max-width: 500px;
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            style="
                max-width: 1200px;
                margin: 0 auto;
                text-align: center;
                padding-top: 80px;
            "
            id="youshi"
        >
            <div style="font-size: 40px; font-weight: bold">
                <span style="color: rgb(34, 45, 57)">产品</span>
                <span style="color: rgb(65, 133, 244)">优势</span>
            </div>
            <div
                style="
                    font-size: 14px;
                    color: rgb(65, 133, 244);
                    font-weight: bold;
                "
            >
                <span>——</span>
                <span> - </span>
                <span>——</span>
            </div>
            <div
                class="xg3"
                style="
                    color: rgb(71, 85, 119);
                    font-size: 16px;
                    margin-top: 20px;
                    line-height: 1.7;
                "
            >
                <div>助力健康管理中心构建智慧体检服务管理体系</div>
                <div>
                    微检如意平台可有效感知体检全量数据，精准勾勒用户画像，实现管理协同、业务推
                </div>
                <div>送、指标跟踪等多种业务需求</div>
            </div>
        </div>
        <div
            style="max-width: 1200px; margin: 80px auto; padding-top: 20px"
            class="flex justify-between align-center xg4"
        >
            <div class="aa">
                <img :src="t2_1" alt="" srcset="" class="t2_1_c t2_1_c1" />
            </div>
            <div class="flex xg5">
                <div>
                    <div class="t2_box">
                        <img :src="t2_2" alt="" srcset="" class="t2_pic" />
                        <span class="t2_title">虚拟医生</span>
                        <p class="t2_content">
                            基于人工智能的指标解读与重点阳性患者的闭环管理。
                        </p>
                    </div>
                    <div class="t2_box">
                        <img :src="t2_4" alt="" srcset="" class="t2_pic" />
                        <span class="t2_title">团检运营</span>
                        <p class="t2_content">
                            智能评估团检订单，输出健康评价，辅助协同全面筛查。
                        </p>
                    </div>
                    <div class="t2_box">
                        <img :src="t2_6" alt="" srcset="" class="t2_pic" />
                        <span class="t2_title">知识随访</span>
                        <p class="t2_content">
                            管理诊疗知识库，精准匹配临床路径，解放随访。
                        </p>
                    </div>
                </div>
                <div>
                    <div class="t2_box">
                        <img :src="t2_3" alt="" srcset="" class="t2_pic" />
                        <span class="t2_title">运营管理</span>
                        <p class="t2_content">
                            体检数据沉淀，健康管理闭环，实现客户智能医疗交互体验。
                        </p>
                    </div>
                    <div class="t2_box">
                        <img :src="t2_5" alt="" srcset="" class="t2_pic" />
                        <span class="t2_title">公众号</span>
                        <p class="t2_content">
                            一站式管理中心门户，提供多学科服务路径，医疗跟踪。
                        </p>
                    </div>
                    <div class="t2_box">
                        <img :src="t2_7" alt="" srcset="" class="t2_pic" />
                        <span class="t2_title">复检复查</span>
                        <p class="t2_content">
                            凭借丰富服务医院项目经验，自主研发人工智能模型，实现患者闭环管理。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div style="background-color: #d0e5fb; padding-top: 80px" id="jieshao">
            <div style="max-width: 1200px; margin: 0 auto; text-align: center">
                <div style="font-size: 40px; font-weight: bold">
                    <span style="color: rgb(34, 45, 57)">产品</span>
                    <span style="color: rgb(65, 133, 244)">介绍</span>
                </div>
                <div
                    style="
                        font-size: 14px;
                        color: rgb(65, 133, 244);
                        font-weight: bold;
                    "
                >
                    <span>——</span>
                    <span> - </span>
                    <span>——</span>
                </div>
                <div
                    class="xg6"
                    style="
                        color: rgb(103, 114, 148);
                        font-size: 16px;
                        margin-top: 20px;
                        line-height: 1.7;
                    "
                >
                    <div>医院健康价值管理</div>
                    <div>
                        涵盖健康管理、圈层管理、回访随访、客情识别、门户管理等，提供客户大健康价值
                    </div>
                    <div>管理服务</div>
                </div>
            </div>
            <div
                style="
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 80px 30px 40px;
                    flex-wrap: wrap;
                "
                class="flex justify-between xg5"
            >
                <div class="t3_box t3_1_1 xg7">
                    <!-- <img :src="t3_1_1" alt="" srcset="" class="t3_img"> -->
                    <div class="t3_img t3_1"></div>
                    <p class="t3_title">智慧随访</p>
                    <div class="t3_content">
                        根据医院需求进行定制化组件随访，沉淀临床知识库，建立患者健康档案，优化客户体验。
                    </div>
                </div>
                <div class="t3_box t3_2_1 xg7">
                    <div class="t3_img t3_2"></div>
                    <p class="t3_title">定制服务</p>
                    <span class="t3_content"
                        >定制开发健康管理中心客户关系管理系统，轻问诊管理/定制算法/数据确权等体系。</span
                    >
                </div>
                <div class="t3_box t3_3_1 xg7">
                    <div class="t3_img t3_3"></div>
                    <p class="t3_title">科研合作</p>
                    <span class="t3_content"
                        >公司已承接多项国家重点研发计划、国家自然科学基金项目，数据组多次获得世界级建模大赛冠军。</span
                    >
                </div>
                <div class="t3_box t3_4_1 xg7">
                    <div class="t3_img t3_4"></div>
                    <p class="t3_title">AI核验</p>
                    <span class="t3_content"
                        >人工智能身份核验，保障客户体检数据安全，采血动态识别，彩超隐私保护。</span
                    >
                </div>
                <div class="t3_box t3_5_1 xg7">
                    <div class="t3_img t3_5"></div>
                    <p class="t3_title">数据治理</p>
                    <span class="t3_content"
                        >根据多年项目实践，承接医疗集团/连锁医院数据集成/数据治理业务，提供一站式解决方案。</span
                    >
                </div>
                <div class="t3_box t3_6_1 xg7">
                    <div class="t3_img t3_6"></div>
                    <p class="t3_title">服务体系</p>
                    <span class="t3_content"
                        >MEDIC.INK为医疗机构提供专业的客户资源管理的聚合服务。</span
                    >
                </div>
            </div>
        </div>
        <div
            style="
                max-width: 1200px;
                margin: 0 auto;
                text-align: center;
                padding-top: 80px;
            "
            id="liucheng"
        >
            <div style="font-size: 40px; font-weight: bold">
                <span style="color: rgb(34, 45, 57)">工作</span>
                <span style="color: rgb(65, 133, 244)">流程</span>
            </div>
            <div
                style="
                    font-size: 14px;
                    color: rgb(65, 133, 244);
                    font-weight: bold;
                "
            >
                <span>——</span>
                <span> - </span>
                <span>——</span>
            </div>
            <div
                class="xg6"
                style="
                    color: rgb(103, 114, 148);
                    font-size: 16px;
                    margin-top: 20px;
                    line-height: 1.7;
                "
            >
                <div>
                    医院客户关系管理需深入临床，客户满意的唯一标准是医疗质量。美佑医疗科技专注于健康管理
                </div>
                <div>
                    客户关系管理系统研究，与合作医院共同见证温度医疗带来的改变。
                </div>
            </div>
        </div>
        <div class="flex justify-between align-center t4_box1 t4_box">
            <img :src="t4_1" alt="" srcset="" class="t4_img" />
            <div class="t4_line"></div>
            <img :src="t4_1" alt="" srcset="" class="t4_img" />
            <div class="t4_line"></div>
            <img :src="t4_3" alt="" srcset="" class="t4_img" />
            <div class="t4_line"></div>
            <img :src="t4_4" alt="" srcset="" class="t4_img" />
        </div>
        <div class="flex justify-between align-center t4_box">
            <div class="t4_text">联系方式</div>
            <div class="t4_text">需求调研</div>
            <div class="t4_text">合作签约</div>
            <div class="t4_text">评价拓展</div>
        </div>
        <div
            :style="{ backgroundImage: 'url(' + t5_5 + ')' }"
            class="t5_box t5_box6"
        >
            <div class="t5_m t5_box6"></div>
            <div class="flex justify-between align-center t5_box2">
                <div class="t5_item">
                    <img :src="t5_1" alt="" srcset="" class="t4_img t4_img1" />
                    <p class="t5_p">100+</p>
                    <span>正式上线</span>
                </div>
                <div class="t5_item">
                    <img :src="t5_2" alt="" srcset="" class="t4_img t4_img1" />
                    <p class="t5_p">6000</p>
                    <span>日随访量</span>
                </div>
                <div class="t5_item">
                    <img :src="t5_3" alt="" srcset="" class="t4_img t4_img1" />
                    <p class="t5_p">80w+</p>
                    <span>推送实例</span>
                </div>
                <div class="t5_item">
                    <img :src="t5_4" alt="" srcset="" class="t4_img t4_img1" />
                    <p class="t5_p">100%</p>
                    <span>跟踪反馈</span>
                </div>
            </div>
            <img :src="t5_6" alt="" srcset="" class="t5_bimg" />
        </div>
        <div
            style="
                max-width: 1200px;
                margin: 0px auto;
                padding-top: 80px;
                text-align: center;
            "
            id="wenti"
        >
            <div style="font-size: 40px; font-weight: bold">
                <span style="color: rgb(34, 45, 57)">常见</span>
                <span style="color: rgb(65, 133, 244)">问题</span>
            </div>
            <div
                style="
                    font-size: 14px;
                    color: rgb(65, 133, 244);
                    font-weight: bold;
                "
            >
                <span>——</span>
                <span> - </span>
                <span>——</span>
            </div>
            <div
                class="xg6"
                style="
                    color: rgb(103, 114, 148);
                    font-size: 16px;
                    margin-top: 20px;
                    line-height: 1.7;
                "
            >
                <div>致力于为医疗机构提供深度医疗资源协同管理服务</div>
                <div>美好生活希望 佑护全民健康</div>
            </div>
        </div>
        <div
            style="max-width: 1200px; margin: 60px auto 80px; padding: 0 30px"
            class="flex justify-between align-center"
        >
            <div class="hhh">
                <el-collapse
                    v-model="activeName"
                    accordion
                    @change="change_show"
                >
                    <el-collapse-item
                        title="如何保证数据安全？"
                        name="1"
                        class="xg7"
                    >
                        <div>
                            美佑已通过公安三级安全等级保护评审，应用区块链技术的安全系统及数据处理方法。所述系统包括核查单元,安全计算单元和数据处理单元。核查单元,用于接收数据需求终端发送的数据处理请求,并根据数据处理请求进行预设信息类型的核查,并在核查通过后将数据处理请求发送给数据处理单元和安全计算单元;数据处理单元,用于根据数据处理请求从分布式存储管理系统中获取对应的需求数据,并将需求数据发送给安全计算单元;安全计算单元,用于根据数据处理请求对需求数据进行处理,获得处理信息,将处理信息发送给核查单元进行核查,并在核查通过后发送给数据需求终端,实现在一个安全计算环境下,对数据安全调用更新,保证数据提供方对源数据的安全可控。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item
                        title="如何定制客户关系管理系统？"
                        name="2"
                        class="xg7"
                    >
                        <div>
                            美佑支持定制化开发客户关系管理系统并形成解决方案。
                            联系我们获取合作意向，提供体检即健康管理CRM理解和业务框架，医院理解业务开展的功能需求以及流程需求，
                            美佑团队通过现场实证后，整理功能需求文档形成产品原型，确认交付时间，进行定制开发。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item
                        title="如何申请科研合作？"
                        name="3"
                        class="xg7"
                    >
                       
                        <div>
                            美佑参与多项国家和省市级重点研发计划/科研课题，以中心医疗机构为专业支撑，通过建立健康管理预测
                             CRM体系以及轻问诊交互平台优化医院业务服务输出流程，持续扩大示范应用范围到合作机构点，形成科研基数，参与医院项目申报，
                             提供平台和跟踪数据分析，辅助医院完善和优化诊疗服务体系，持续发展。
                        </div>
                    </el-collapse-item>
                    <el-collapse-item
                        title="如何资源合作？"
                        name="4"
                        class="xg7"
                    >
                        <div>
                            美佑致力于研发体检专业CRM系统，创新健康管理服务模式与服务业态，整合业务，从理念、疾病类型、运行机制、 
                            模式和后续推广等方面进行模式创新，具有卓越的辅助效应，具备合作的可持续性和可复制性。美佑拥抱合作，目前已与国内数家
                            HIS、体检、健康风险战略合作伙伴进行集成研发，提供便捷应用渠道，智能整合资源。厂商合作可直接与售前团队取得联系。
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div class="aa xg6">
                <img :src="t6" alt="" srcset="" />
            </div>
        </div>
        <div style="background-color: #d0e5fb; padding-top: 1px" class="xg6">
            <div
                style="
                    max-width: 1200px;
                    margin: 70px auto;
                    text-align: center;
                    padding-bottom: 1px;
                "
            >
                <div style="font-size: 40px; font-weight: bold">
                    <span style="color: rgb(34, 45, 57)">服务</span>
                    <span style="color: rgb(65, 133, 244)">说明</span>
                </div>
                <div
                    style="
                        font-size: 14px;
                        color: rgb(65, 133, 244);
                        font-weight: bold;
                    "
                >
                    <span>——</span>
                    <span> - </span>
                    <span>——</span>
                </div>
                <div
                    style="
                        color: rgb(103, 114, 148);
                        font-size: 16px;
                        margin-top: 20px;
                        line-height: 1.7;
                    "
                >
                    <div>
                        响应国家卫生健康委办公厅关于开展进一步改善医疗服务行动计划效果第三方工作的通知，美佑
                    </div>
                    <div>
                        医疗科技基于行业领先的客户关系管理研究沉淀，提供体检代理随访服务。
                    </div>
                </div>
            </div>
            <div
                style="
                    max-width: 1150px;
                    margin: 80px auto 0;
                    padding: 20px 0 90px;
                "
                class="flex justify-between"
            >
                <div class="fw_box">
                    <div class="fw_box_t">
                        <div class="fw_box_ttitle">健康管理</div>
                        <span class="fw_box_ttitle1">定制版</span>
                    </div>
                    <div class="fw_box_c">
                        <div>
                            <!-- <i class="el-icon-caret-right"></i> -->
                            ▸ 门户品宣
                        </div>
                        <div>▸ 流程定制</div>
                        <div>▸ 检前定制</div>
                        <div>▸ 检后定制</div>
                        <div>▸ 检中集成</div>
                        <div>▸ 智慧质控</div>
                        <div>▸ 圈层管理</div>
                    </div>
                    <div class="fw_box_t1">
                        <!-- <el-button type="info btn_fw">立即咨询</el-button> -->
                        <button class="btn_fw">立即咨询</button>
                    </div>
                </div>
                <div class="fw_box">
                    <div class="fw_box_t">
                        <div class="fw_box_ttitle">体检运营</div>
                        <span class="fw_box_ttitle1">公立版</span>
                    </div>
                    <div class="fw_box_c">
                        <div>
                            <!-- <i class="el-icon-caret-right"></i> -->
                            ▸ 门户品宣
                        </div>
                        <div>▸ 团检运营</div>
                        <div>▸ 体检报告</div>
                        <div>▸ 专家预约</div>
                        <div>▸ 推荐平台</div>
                        <div>▸ 圈层管理</div>
                        <div>▸ 检后管理</div>
                    </div>
                    <div class="fw_box_t1">
                        <!-- <el-button type="info btn_fw">立即咨询</el-button> -->
                        <button class="btn_fw">立即咨询</button>
                    </div>
                </div>
                <div class="fw_box">
                    <div class="fw_box_t">
                        <div class="fw_box_ttitle">健康平台</div>
                        <span class="fw_box_ttitle1">区域版</span>
                    </div>
                    <div class="fw_box_c">
                        <div>
                            <!-- <i class="el-icon-caret-right"></i> -->
                            ▸ 门户品宣
                        </div>
                        <div>▸ 检查报告</div>
                        <div>▸ 检验报告</div>
                        <div>▸ 综合预约</div>
                        <div>▸ 健康商城</div>
                        <div>▸ 医患问诊</div>
                        <div>▸ 公卫上报</div>
                    </div>
                    <div class="fw_box_t1">
                        <!-- <el-button type="info btn_fw">立即咨询</el-button> -->
                        <button class="btn_fw">立即咨询</button>
                    </div>
                </div>
            </div>
        </div>
        <div
            :style="{ backgroundImage: 'url(' + t7 + ')' }"
            class="t5_box t5_box1"
        >
            <div class="t5_m t5_box1"></div>
            <div
                style="
                    max-width: 1200px;
                    margin: 120px auto;
                    text-align: center;
                    z-index: 10;
                    position: relative;
                "
            >
                <div style="font-size: 40px; font-weight: bold">
                    <span style="color: #fff">预约</span>
                    <span style="color: #fff">演示</span>
                </div>
                <div style="font-size: 14px; color: #fff; font-weight: bold">
                    <span>——</span>
                    <span> - </span>
                    <span>——</span>
                </div>
                <div
                    style="
                        color: #fff;
                        font-size: 16px;
                        margin-top: 20px;
                        line-height: 1.7;
                    "
                >
                    <div>
                        支持现场系统功能演示，客户关系管理咨询解决方案，安排就医计划，提高客户就医
                    </div>
                    <div>服务体验，改善医患关系。</div>
                </div>
            </div>
            <img :src="t5_6" alt="" srcset="" class="t5_bimg t5_bimg1" />
        </div>
        <div
            style="max-width: 1000px; margin: 90px auto; padding-right: 100px"
            class="flex justify-between xg5 xg8"
        >
            <div>
                <img :src="t8" alt="" srcset="" class="t8_img" />
            </div>
            <div style="text-align: center">
                <div style="font-size: 40px; font-weight: bold">
                    <span style="color: rgb(34, 45, 57)">生态</span>
                    <span style="color: rgb(65, 133, 244)">平台</span>
                </div>

                <div
                    style="
                        color: #677294;
                        font-size: 14px;
                        margin-top: 20px;
                        line-height: 26px;
                    "
                >
                    <div class="t8_box xg6">
                        美佑以实践场景和科研成果为依托，打造新型医疗客户关系管理生态。互联网医院协同平台支持多业态数据采集与定制建模，精准勾勒客户健康画像。区块链确权平台保障客户全生命周期健康档案归档和确权授权更新，有效感知数据维度。圈层管理平台可灵活创建家庭医生健康管理计划，实时跟踪计划，真正实现自动化、精细化、智能化社区协同健康管理。
                    </div>
                    <div class="t8_foot xg9">
                        <el-button type="primary" class="t8_btn"
                            >区块</el-button
                        >
                        <el-button type="primary" class="t8_btn"
                            >档案</el-button
                        >
                        <el-button type="primary" class="t8_btn"
                            >圈层</el-button
                        >
                    </div>
                </div>
            </div>
        </div>
        <div
            :style="{ backgroundImage: 'url(' + t9 + ')' }"
            class="t8_box1"
            id="hezuo"
        >
            <div class="t8_m1"></div>
            <div class="t88_box t88_box1">
                <div style="font-size: 40px; font-weight: bold">
                    <span style="color: #000">合作</span>
                    <span style="color: rgb(65, 133, 244)">申请</span>
                </div>
                <div
                    class="xg10"
                    style="
                        font-size: 14px;
                        color: rgb(65, 133, 244);
                        font-weight: bold;
                    "
                >
                    <span>——</span>
                    <span> - </span>
                    <span>——</span>
                </div>
                <div
                    class="xg6"
                    style="
                        color: #000;
                        font-size: 16px;
                        margin-top: 20px;
                        line-height: 1.7;
                        margin-bottom: 50px;
                    "
                >
                    <div>
                        合作共赢，请您填写资料，团队将于二小时内响应您的需求。
                    </div>
                </div>
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="100px"
                    class="demo-ruleForm"
                >
                    <div class="flex two_box justify-between xg5 two_box1">
                        <el-form-item prop="name">
                            <el-input
                                v-model="ruleForm.name"
                                placeholder="请填写您的姓名："
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="phone">
                            <el-input
                                v-model="ruleForm.phone"
                                placeholder="请填写您的联系方式："
                                type="number"
                            ></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item prop="company" class="two_box1">
                        <el-input
                            v-model="ruleForm.company"
                            placeholder="请填写您的单位："
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="demand" class="two_box1">
                        <el-input
                            type="textarea"
                            v-model="ruleForm.demand"
                            placeholder="请简述您的需求："
                        ></el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0; margin-top: 30px">
                        <el-button
                            type="primary"
                            @click="submitForm('ruleForm')"
                            style="padding: 20px 50px"
                            >提交</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div
            style="
                background-color: #d0e5fb;

                padding: 1px 30px;
            "
        >
            <div
                style="
                    max-width: 1200px;
                    margin: 30px auto;
                    padding: 20px 0 10px;
                    flex-wrap: wrap;
                    text-align: center;
                "
                class="flex justify-between xg5"
            >
                <div class="b_text">
                    <div>商务合作：business@medicup.cn</div>
                    <div>加入团队：hr@medicup.cn</div>
                    <div>
                        公司地址：中国(四川)自由贸易试验区成都高新区天府软件园
                        <span>B7（203-204/215）</span>
                    </div>
                    <div>
                        友情链接：四川省人民医院 四川大学华西医院 丁香园 动脉网
                        健康界
                    </div>
                    <div class="share">
                        分享到：
                        <a
                            target="_blank"
                            href="http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https%3A%2F%2Fwww.medicup.cn%2F&title=%E7%BE%8E%E4%BD%91%2F%E5%BE%AE%E6%A3%80%E5%A6%82%E6%84%8F%2F%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%2F%E4%BD%93%E6%A3%80CRM%2F%E4%BD%93%E6%A3%80%E6%8B%93%E5%AE%A2%2F%E4%BD%93%E6%A3%80%E8%BF%90%E8%90%A5%2F%E6%99%BA%E6%85%A7%E4%BD%93%E6%A3%80&desc=%E7%BE%8E%E4%BD%91%2F%E5%BE%AE%E6%A3%80%E5%A6%82%E6%84%8F%2F%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%2F%E4%BD%93%E6%A3%80CRM%2F%E4%BD%93%E6%A3%80%E6%8B%93%E5%AE%A2%2F%E4%BD%93%E6%A3%80%E8%BF%90%E8%90%A5%2F%E6%99%BA%E6%85%A7%E4%BD%93%E6%A3%80&summary=%E7%BE%8E%E4%BD%91%2F%E5%BE%AE%E6%A3%80%E5%A6%82%E6%84%8F%2F%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%2F%E4%BD%93%E6%A3%80CRM%2F%E4%BD%93%E6%A3%80%E6%8B%93%E5%AE%A2%2F%E4%BD%93%E6%A3%80%E8%BF%90%E8%90%A5%2F%E6%99%BA%E6%85%A7%E4%BD%93%E6%A3%80&site=%E7%BE%8E%E4%BD%91%2F%E5%BE%AE%E6%A3%80%E5%A6%82%E6%84%8F%2F%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%2F%E4%BD%93%E6%A3%80CRM%2F%E4%BD%93%E6%A3%80%E6%8B%93%E5%AE%A2%2F%E4%BD%93%E6%A3%80%E8%BF%90%E8%90%A5%2F%E6%99%BA%E6%85%A7%E4%BD%93%E6%A3%80"
                        >
                            <div>
                                <img
                                    :src="qq"
                                    alt=""
                                    srcset=""
                                    class="share_img"
                                />
                            </div>
                        </a>
                        <a
                            target="_blank"
                            href="https://service.weibo.com/share/share.php?url=https%3A%2F%2Fwww.medicup.cn%2F&title=%E7%BE%8E%E4%BD%91%2F%E5%BE%AE%E6%A3%80%E5%A6%82%E6%84%8F%2F%E5%81%A5%E5%BA%B7%E7%AE%A1%E7%90%86%2F%E4%BD%93%E6%A3%80CRM%2F%E4%BD%93%E6%A3%80%E6%8B%93%E5%AE%A2%2F%E4%BD%93%E6%A3%80%E8%BF%90%E8%90%A5%2F%E6%99%BA%E6%85%A7%E4%BD%93%E6%A3%80&pic=https%3A%2F%2Fccdn1.goodq.top%2Fcaches%2F7b81c3f2f020ca60e651ffd88e4a6d0a%2FaHR0cHM6Ly93d3cubWVkaWN1cC5jbi9xZnktY29udGVudC91cGxvYWRzLzIwMjIvMDIvOTdmYWMxY2FiNGFmNjllOTIwMTQwNmJkOTE5ZjA1ZmMtOTAud2VicA_p_p100_p_3D_p_p100_p_3D.webp&appkey="
                        >
                            <div>
                                <img
                                    :src="weibo"
                                    alt=""
                                    srcset=""
                                    class="share_img"
                                />
                            </div>
                        </a>

                        <el-popover
                            placement="top-start"
                            width="120"
                            trigger="hover"
                            v-model="visible"
                        >
                            <div style="text-align: right; margin: 0">
                                <img :src="wechat" alt="" srcset="" />
                            </div>
                            <div slot="reference">
                                <img
                                    :src="weixin"
                                    alt=""
                                    srcset=""
                                    class="share_img"
                                />
                            </div>
                        </el-popover>
                    </div>
                </div>
                <div>
                    <img :src="official" alt="" srcset="" />
                    <div>美佑官方</div>
                </div>
            </div>
        </div>
        <div
            style="
                background-color: #000;
                padding: 30px 0 16px;
                color: #fff;
                text-align: center;
            "
        >
            <a
                target="_blank"
                style="cursor: pointer"
                href="https://beian.miit.gov.cn/"
                >蜀ICP备19020978号-2</a
            >
            <a href="" style="cursor: pointer">
                <img :src="beian" alt="" srcset="" class="beian_img" />
                <span>四川省成都市高新区网安大队备-51019002002214号</span>
            </a>
            <p style="font-size: 12px">
                © Copyright 2019-2021 美佑医疗 医疗客户关系管理聚合平台
            </p>
        </div>
        <div class="fix_top" id="header_1" v-if="show2">
            <div
                class="flex justify-between align-center"
                style="max-width: 1200px; margin: 0 auto; padding-right: 30px"
            >
                <div></div>
                <ul>
                    <li  @click="click_header('1')"><a href="#page_top"> 美佑医疗</a></li>
                    <li  @click="click_header('2')"><a href="#youshi"> 优势体系</a></li>
                    <li  @click="click_header('3')"><a href="#jieshao"> 产品介绍</a></li>
                    <li  @click="click_header('4')"><a href="#liucheng"> 工作流程</a></li>
                    <li  @click="click_header('5')"><a href="#wenti"> 常见问题</a></li>
                    <!-- <li><a href=""> 知识更新</a></li> -->
                </ul>
            </div>
        </div>
        <div class="fix_right" @click="toggle_show">
            <i class="el-icon-s-fold"></i>
        </div>
        <div class="back_menu" v-show="show_icon">
           <div class="fix_right" @click="toggle_show">
            <i class="el-icon-close"></i>
        </div>
            <div style="margin:60px auto 0;">
            <ul @click="toggle_show">
                <li><a href="">美佑医疗<div> /home</div></a></li>
                 <li><a href="#youshi"> 优势体系</a></li>
                 <li><a href="#jieshao"> 产品介绍</a></li>
                 <li><a href="#liucheng"> 工作流程</a></li>
                 <li><a href="#wenti"> 常见问题</a></li>
            </ul>
    </div>
        </div>

    </div>
</template>

<script>
import MyComponent from "./MyComponent.vue";
import axios from "@/utils/request";
export default {
    components: { MyComponent },
    data() {
        return {
            activeName: "1",
            logo: require("../../assets/logo.png"),
            t1: require("../../assets/t1.png"),
            t2_1: require("../../assets/t2_1.png"),
            t2_2: require("../../assets/t2_2.png"),
            t2_3: require("../../assets/t2_3.png"),
            t2_4: require("../../assets/t2_4.png"),
            t2_5: require("../../assets/t2_5.png"),
            t2_6: require("../../assets/t2_6.png"),
            t2_7: require("../../assets/t2_7.png"),
            t3_1: require("../../assets/t3_1.png"),
            t3_1_1: require("../../assets/t3_1_1.png"),
            t3_2: require("../../assets/t3_2.png"),
            t3_2_1: require("../../assets/t3_2_1.png"),
            t3_3: require("../../assets/t3_3.png"),
            t3_3_1: require("../../assets/t3_3_1.png"),
            t3_4: require("../../assets/t3_4.png"),
            t3_4_1: require("../../assets/t3_4_1.png"),
            t3_5: require("../../assets/t3_5.png"),
            t3_5_1: require("../../assets/t3_5_1.png"),
            t3_6: require("../../assets/t3_6.png"),
            t3_6_1: require("../../assets/t3_6_1.png"),
            t4_1: require("../../assets/t4_1.png"),
            t4_3: require("../../assets/t4_3.png"),
            t4_4: require("../../assets/t4_4.png"),
            t5_1: require("../../assets/t5_1.png"),
            t5_2: require("../../assets/t5_2.png"),
            t5_3: require("../../assets/t5_3.png"),
            t5_4: require("../../assets/t5_4.png"),
            t5_5: require("../../assets/t5_5.png"),
            t5_6: require("../../assets/t5_6.png"),
            t6: require("../../assets/t6.png"),
            t7: require("../../assets/t7.png"),
            t8: require("../../assets/t8.svg"),
            t9: require("../../assets/t9.png"),
            wechat: require("../../assets/wechat.jpg"),
            official: require("../../assets/official.png"),
            weibo: require("../../assets/weibo.png"),
            weixin: require("../../assets/weixin.png"),
            qq: require("../../assets/qq.png"),
            weibo1: require("../../assets/weibo.png"),
            weixin1: require("../../assets/weixin.png"),
            qq1: require("../../assets/qq.png"),
            beian: require("../../assets/beian-1.png"),
            visible: false,

            ruleForm: {
                name: "",
                demand: "",
                phone: "",
                company: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入您的姓名",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        message: "长度至少在 2 个字符",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入联系方式",
                        trigger: "blur",
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "长度在 11 位数字",
                        trigger: "blur",
                    },
                ],
                company: [],
                demand: [
                    {
                        required: true,
                        message: "请填写您的需求",
                        trigger: "blur",
                    },
                ],
            },
            scrollDistance: 0,
            show2: false,
            show_icon:false,
            header_number:1
        };
    },
    methods: {
        click_header(e){
this.header_number=e;
        },
        change_show(e) {
            console.log(e);
            if(e){
                this.activeName=e
            }
        },
        toggle_show(){
            this.show_icon=!this.show_icon
        },
        async put_form() {
            // 接收数据时不需要再解构
            const data = await axios({
                // 去掉服务器的路径
                url: "cooperate?do=add_data",
                method: "POST",
                data: {
                    ...this.ruleForm,
                },
            });
            console.log(data);
            if (data.code == 200) {
                this.$message({
                    message: "提交成功",
                    type: "success",
                    offset: 200,
                });
                this.resetForm("ruleForm");
            }
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.put_form();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleScroll() {
            const width = window.innerWidth;

            if (width >= 1000) {
                const distance =
                    window.pageYOffset || document.documentElement.scrollTop;
                this.scrollDistance = distance;
                if (this.scrollDistance > 62) {
                    this.show2 = true;
                } else if (this.scrollDistance < 63) {
                    this.show2 = false;
                }
            }else{
                this.show2 = false;
            }
        },
    },
    computed: {
        sum() {
            const result = this.count + this.count2;
            return result;
        },
    },
    created() {
        // 监听窗口大小变化
        window.addEventListener("resize", this.handleScroll);
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleScroll);
    },
};
</script>

<style>
.aa {
    transform: translate(-50%, -50%); /* 保证图片居中显示 */
    animation: floatAndShake 4s ease-in-out infinite; /* 应用晃动动画 */
}

@keyframes floatAndShake {
    0% {
        transform: translateY(0);
    } /* 起始位置 */
    50% {
        transform: translateY(-20px);
    } /* 中间位置，可以调整数值和动画路径以实现不同的晃动效果 */
    100% {
        transform: translateY(0);
    } /* 回到起始位置 */
}

body {
    margin: 0;
}
.flex {
    display: flex;
}
.justify-between {
    justify-content: space-between;
}
.align-center {
    align-items: center;
}
header {
    /* position: absolute; */
    /* top: 0; */
    width: 100%;
    max-width: 1200px;
    /* left: 0; */
}
header img {
    max-height: 50px;
    width: auto;
    margin: 10px 0;
}
header a ,.fix_top a{
    width: 100%;
    height: 100%;
    display: block;
    margin-bottom: 8px;
}
header ul .act {
    color: #4185f4;

    border-bottom: 1px solid #4185f4;
}
header ul li {
    display: inline-block;
    position: relative;
    z-index: auto;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 42px;
    
}
a {
    color: inherit;
    text-decoration: none;
    cursor: default;
    font-size: 15px;
}
.btn_l {
    width: 200px;
    height: 55px;
    line-height: 55px;
    background-color: rgb(65, 133, 244);
    color: #fff;
    display: block;
    text-align: center;
}
.btn_r {
    background-color: rgb(45, 45, 45);
}
.shake {
    transform: translateY(-10px);
    transition: transform 0.3s ease-in-out;
}
.t2_pic {
    width: 43px;
    height: 43px;
    vertical-align: middle;
}
.t2_box {
    border: 1px solid rgba(236, 236, 236, 1);
    margin: 15px;
    padding: 28px 20px 30px;
    max-width: 294px;
}
.t2_title {
    font-size: 18px;
    font-weight: bold;
    color: #222d39;
    padding: 0 0 0 18px;
    vertical-align: middle;
}
.t2_content {
    line-height: 26px;
    color: rgb(103, 114, 148);
    font-size: 13px;
    font-family: 微软雅黑;
    padding-left: 65px;
    margin: 8px 0 0 0;
}
.t3_box {
    background-color: #ffffff;
    transition: all 0.3s ease 0s;
    color: #677294;
    font-size: 13px;
    text-align: center;
    max-width: 375px;
    padding: 30px 40px;
    box-sizing: border-box;
    border-bottom: 3px solid rgba(65, 133, 244, 1);
    margin: 0 0 40px 0;
}
.t3_img {
    width: 65px;
    height: 65px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.t3_title {
    font-size: 18px;
    font-weight: bold;
    color: #222d39;
    margin-bottom: 20px;
}
.t3_content {
    font-size: 13px;
    line-height: 26px;
}
.t3_1 {
    background-image: url("@/assets/t3_1_1.png"); /* 设置默认的背景图片 */
    background-size: cover; /* 背景图片覆盖整个元素区域 */
    transition: background-image 0.3s;
}

.t3_1_1:hover .t3_1 {
    background-image: url("@/assets/t3_1.png"); /* 鼠标悬停时的背景图片 */
}
.t3_2 {
    background-image: url("@/assets/t3_2_1.png");
    background-size: cover;
    transition: background-image 0.3s;
}

.t3_2_1:hover .t3_2 {
    background-image: url("@/assets/t3_2.png");
}
.t3_3 {
    background-image: url("@/assets/t3_3_1.png");
    background-size: cover;
    transition: background-image 0.3s;
}

.t3_3_1:hover .t3_3 {
    background-image: url("@/assets/t3_3.png");
}
.t3_4 {
    background-image: url("@/assets/t3_4_1.png");
    background-size: cover;
    transition: background-image 0.3s;
}

.t3_4_1:hover .t3_4 {
    background-image: url("@/assets/t3_4.png");
}
.t3_5 {
    background-image: url("@/assets/t3_5_1.png");
    background-size: cover;
    transition: background-image 0.3s;
}

.t3_5_1:hover .t3_5 {
    background-image: url("@/assets/t3_5.png");
}
.t3_6 {
    background-image: url("@/assets/t3_6_1.png");
    background-size: cover;
    transition: background-image 0.3s;
}

.t3_6_1:hover .t3_6 {
    background-image: url("@/assets/t3_6.png");
}
.t4_img {
    width: 90px;
    height: 90px;
}
.t4_line {
    border-bottom: 1px solid #222d39;
    flex-grow: 1;
}
.t4_text {
    font-size: 14px;
    font-weight: bold;
    color: #222d39;
    width: 90px;
    text-align: center;
}
.t5_box {
    height: 445px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    padding-top: 1px;
}
.t5_m {
    background-color: rgba(34, 45, 57, 0.7);
    width: 100%;
    height: 445px;
    position: absolute;
    top: 0;
    left: 0;
}
.t8_m1 {
    background-color: rgba(34, 45, 57, 0.7);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 660px;
    height: 100%;
}
.t5_bimg {
    width: 100%;
    height: 38.15px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.t5_item {
    text-align: center;
    color: #fff;
    z-index: 10;
}
.t5_p {
    font-size: 46px;
    margin: 20px 0 8px;
    font-family: "Hiragino Sans GB";
}
.t5_item span {
    font-size: 18px;
}
.el-collapse-item {
    max-width: 595px;
    margin: 10px 0;
}
.el-collapse-item__header {
    height: 51px;
    background-color: #deeefc !important;
    padding-left: 20px;
    font-size: 16px !important;
}
.is-active {
    background-color: #4185f4 !important;
    color: #fff !important;
}
.el-collapse {
    border: 0 !important;
}
.el-collapse-item__content {
    font-size: 14px !important;
    color: rgb(103, 114, 148) !important;
    padding: 20px 20px 25px;
    line-height: 28px !important;
}
.fw_box {
    width: 350px;
    height: 551px;
    text-align: center;
    margin: 0 20px;
}
.fw_box_t {
    background-color: #4185f4;
    padding: 30px 0 20px;
}
.fw_box_ttitle {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 2px;
}
.fw_box_ttitle1 {
    font-size: 24px;
    color: #fff;
}
.fw_box_c {
    background-color: #fff;
    padding: 20px;
    color: rgb(71, 85, 119);
    line-height: 2.6;
    font-family: sans-serif;
}
.btn_fw {
    width: 134px;
    height: 51px;
    line-height: 51px;
    color: #fff;
    background-color: #222d39;
    border: 0;
    cursor: pointer;
}
.btn_fw:hover {
    background-color: #4e7aa8;
    transition: 0.5s;
}
.fw_box_t1 {
    background-color: #4185f4;
    padding: 30px;
}
.t5_bimg1 {
    bottom: 150px;
}
.t5_box1 {
    height: 508px;
}
.t8_img {
    width: 240px;
    height: 240px;
}
.t8_btn {
    width: 172px;
    height: 93px;
    font-size: 16px !important;
    background-color: rgb(81, 148, 255) !important;
}
.t8_btn:hover {
    background-color: rgb(56, 130, 250) !important;
}
.t8_foot {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;
}
.t8_box {
    box-sizing: border-box;
    width: 610px;
    padding: 0 20px;
}
.t8_box1 {
    min-height: 660px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    position: relative;
    padding-top: 1px;
    padding-bottom: 1px;
}
.t88_box {
    background-color: #deeefc;
    border-radius: 4px;
    padding: 50px;
    width: 760px;
    margin: 50px auto;
    text-align: center;
    z-index: 10;
    position: relative;
    box-sizing: border-box;
}
.el-form-item__content {
    margin-left: 0 !important;
}
.el-input__inner {
    height: 53px !important;
}
.two_box .el-input__inner {
    width: 320px;
}
.b_text {
    text-align: left;
    color: rgb(103, 114, 148);
    line-height: 1.5;
}
.share {
    margin-top: 30px;
    color: #000;
    display: flex;
}
.social-share .icon-qzone:before {
    content: "\f08a";
}
.share_img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: 0 6px;
}
.el-popover {
    min-width: none;
}
.beian_img {
    margin-left: 40px;
    vertical-align: text-bottom;
    margin-right: 2px;
}
.fix_top {
    width: 100%;
    height: 53px;
    background-color: rgba(34, 45, 57, 0.9);
    color: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
}
.fix_top ul li {
    display: inline-block;
    position: relative;
    z-index: auto;
    vertical-align: middle;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 21px;
}
.over_page {
    min-height: 100vh;
}
.none {
    display: none;
}
.t2_1_c {
    width: 440px;
    height: 368.5px;
    margin-right: 40px;
}
.t4_box {
    max-width: 1000px;
    margin: 25px auto 100px;
    text-align: center;
    padding: 0 30px;
}
.t4_box1 {
    max-width: 1000px;
    margin: 100px auto 20px;
    text-align: center;
    padding: 0 30px;
}
.t5_box2 {
    max-width: 1000px;
    margin: 100px auto 100px;
    text-align: center;
    padding: 0 30px;
}
.hhh {
    height: 480px;
    width: 100%;
}
.back_menu{
    width:100%;height:100vh;
    background-color: #deeefc;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    
}
.back_menu ul {
   padding-left: 0;
}

.back_menu ul li {
     list-style:none; 
     text-align: center;
  
      
}
.back_menu ul li:hover {
    background-color:#379cf4;
    color: #fff;
   
}
.back_menu a {
    display: block;
   padding: 10px 0;
   width: 100%;
   height: 100%;
   font-size:16px;
}
.title_size{
    font-size: 45px;
}
@media screen and (max-width: 1000px) {
    header,
    .xg3,
    #xg2,
    .xg6 {
        display: none;
    }
    #xg1,
    .xg4,
    .xg5 {
        display: block;
        text-align: center;
    }
    .xg7 {
        max-width: unset;
    }
    .xg8 {
        padding-right: 0 !important;
    }
    .xg9 {
        padding: 0 30px;
    }
    .fix_right {
        width: 47px;
        background-color: #4185f4;
        position: fixed;
        top: 10px;
        right: 10px;
        color: #fff;
        font-size: 24px;
        text-align: center;
        padding: 7px 0;
        cursor: pointer;
    }
    .xg10 {
        margin: 20px 0;
    }
    .two_box1 input {
        width: 100% !important;
    }
    .t88_box1 {
        width: unset;
        padding: 50px 15px;
    }
    .t2_1_c1 {
        width: 100%;
        height: auto;
        margin-right: 0;
        max-width: 700px;
    }

    .t4_img {
        width: 80px;
        height: 80px;
    }
    .t4_img1 {
        width: 70px;
        height: 70px;
    }
    .t4_box {
        padding: 0 5px;
    }
    .t5_p {
        font-size: 26px;
    }
    .t5_item span {
        font-size: 14px;
    }
    .t5_box6 {
        height: 300px;
    }
    .t5_box2 {
        margin: 60px 0;
        padding: 0 10px;
    }
    .hhh {
        height: unset;
        min-height: 480px;
        width: 100%;
    }
    .t2_content {
        padding-left: 0;
    }
    .title_size{
    font-size: 36px;
}
.t2_box{
    max-width: unset;
}
}
</style>